import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa'
const Share = ({ post }) => {
  const url = `https://khalid.abolkog.com/${post.slug}`

  return (
    <div>
      <h1 className="font-semibold text-2xl text-gray-500 mb-5">Share post</h1>
      <div className="flex flex-row justify-between">
        <FacebookShareButton url={url} title={post.title} quote={post.excerpt}>
          <FaFacebook color="#3b5998" size={30} />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={post.title}
          hashtags={post.tags.map(tag => tag.slug)}
        >
          <FaTwitter color="#55acee" size={30} />
        </TwitterShareButton>
        <WhatsappShareButton url={url} title={post.title}>
          <FaWhatsapp color="#4dc247" size={30} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}

export default Share
