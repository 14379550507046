import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Author from '../components/Author'
import PostCard from '../components/PostCard'
import Share from '../components/Share'
// import Comments from '../components/Comments'

export const query = graphql`
  query($slug: String!) {
    post: strapiPost(slug: { eq: $slug }) {
      title
      slug
      body
      excerpt
      created_on
      author {
        name
        username
        bio
        social_links {
          github
          linkedin
          instagram
          twitter
        }
        photo {
          url
        }
      }
      tags {
        title
        slug
      }
    }
  }
`

const PostTempalte = ({ data: { post } }) => {
  return (
    <Layout>
      <SEO title={post.title} description={post.excerpt} />

      <PostCard post={post} withLinks={false} />

      <Share post={post} />
      {/* <Comments post={post} /> */}

      <Author author={post.author} />
    </Layout>
  )
}

export default PostTempalte
