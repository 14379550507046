import React from 'react'
import { FaInstagram, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

const Author = ({ author }) => {
  const social_links = author.social_links || {}

  const parseSocialMeda = social => {
    switch (social) {
      case 'instagram':
        return {
          icon: FaInstagram,
          url: `https://instagram.com/${social_links[social]}`,
        }
      case 'linkedin':
        return {
          icon: FaLinkedin,
          url: `https://linkedin.com/in/${social_links[social]}`,
        }
      case 'twitter':
        return {
          icon: FaTwitter,
          url: `https://twitter.com/${social_links[social]}`,
        }
      case 'github':
        return {
          icon: FaGithub,
          url: `https://github.com/${social_links[social]}`,
        }
      default: {
        return {
          icon: '',
          url: '',
        }
      }
    }
  }
  return (
    <div className="w-full flex flex-col text-center md:text-left md:flex-row shadow bg-white mt-10 mb-10 p-6">
      <div className="w-full md:w-1/5 flex justify-center md:justify-start pb-4">
        <img
          alt="author"
          src={author.photo.url}
          className="rounded-full shadow h-32 w-32"
        />
      </div>
      <div className="flex-1 flex flex-col justify-center md:justify-start">
        <p className="font-semibold text-2xl">{author.name}</p>
        <p className="pt-2">{author.bio}</p>
        <div className="flex items-center justify-center md:justify-start text-2xl no-underline text-blue-800 pt-4">
          {Object.keys(social_links).map(social => {
            const socialMeda = parseSocialMeda(social)
            if (!socialMeda) return null
            return (
              <a
                key={social}
                href={socialMeda.url}
                target="_blank"
                rel="noreferrer"
                className="pl-4"
              >
                <socialMeda.icon />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Author
